import { decode } from 'js-base64';

let Config = {
    websiteUrl: window.location.protocol + "//" + window.location.host + "/",
    apiUrl: window.location.protocol + "//backend.eg.phocas.tech/api/",
    websocketUrl: window.location.protocol + "//app.eg.phocas.tech/",
    user: !!sessionStorage.getItem('user') ? JSON.parse(decode(sessionStorage.getItem('user'))) : null
}

//apiUrl: window.location.protocol + "//backend.eg.phocas.tech/api/",
export default Config;