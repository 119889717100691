import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Languages from 'languages-js';
import Config from './config';

// --- --- Views --- --- //
import App from './App';
import Dashboard from './routes/Dashboard';
import Error404 from './routes/404';
import Login from './routes/Login';

// Movements
import Movements from './routes/movements/Movements';
import {default as MovementsList} from './routes/movements/List';
import {default as MovementsView} from './routes/movements/View';
import {default as MovementsCreate} from './routes/movements/Create';

// Credentials
import Credentials from './routes/credentials/Credentials';
import {default as CredentialsList} from './routes/credentials/List';
import {default as CredentialsView} from './routes/credentials/View';
import {default as CredentialsCreate} from './routes/credentials/Create';


Languages.init(['pt_PT', 'en_US'], Config.websiteUrl + 'languages/', () => {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          {(!sessionStorage.getItem('auth') ? <Route path='*' element={<Login />} /> : 
          <Route path='/' element={<App />}>
            <Route index element={<Dashboard />} />
            <Route path='login' element={<Login />} />
            <Route path='movements' element={<Movements />} >
              <Route index element={<MovementsList />} />
              <Route path='create' element={<MovementsCreate />} />
              <Route path=':movementId' element={<MovementsView />} />
            </Route>
            <Route path='credentials' element={<Credentials />} >
              <Route index element={<CredentialsList />} />
              <Route path='create' element={<CredentialsCreate />} />
              <Route path=':credentialId' element={<CredentialsView />} />
            </Route>
            <Route path="*" element={<Error404 />} />
          </Route>)}
        </Routes>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
});



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();