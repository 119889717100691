import React, { Component } from "react";
import axios from "axios";
import { ArrowSmDownIcon } from '@heroicons/react/outline'
import { Link } from "react-router-dom";


export default class List extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: null,
    };
  }

  async componentDidMount() {
    await axios.create({
      headers: { 'Authorization': 'Basic ' + sessionStorage.getItem('auth') }
    }).get('movement')
      .then((response) => {
        console.log(response, response.data.length, response.statusText);
        if (response.statusText === 'OK' && response.data.length > 0) {
          this.setState({
            isLoading: false,
            data: response.data
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      })

  }

  render() {
    return (
      <div className="-mb-3">
        {(this.state.isLoading ?
          <div className="bg-neutral-800 rounded-md p-4 max-w-sm w-full mx-auto">
            <div className="flex space-x-4 animate-pulse">
              <div className="grow shrink-0 flex flex-col space-y-1">
                <div className="rounded bg-neutral-700 h-5 w-16"></div>
                <div className="rounded bg-neutral-700 h-4 w-24"></div>
                <div className="rounded bg-neutral-700 h-5 w-16"></div>
              </div>
              <div className="shrink-0 grow-0 flex flex-col">
                <div className="p-2 bg-neutral-700 rounded ml-auto">
                  <div className="rounded bg-neutral-600 h-3 w-16"></div>
                </div>
                <div className="mt-auto text-right text-neutral-500">
                  <div className="rounded bg-neutral-700 h-3 w-16 ml-auto"></div>
                </div>
              </div>
            </div>
          </div>
          : (!!this.state.data ? this.state.data.map((movement) => {
            return (
              <Link key={movement.id} to={'/movements/' + movement.id} className="bg-neutral-800 rounded-md p-4 max-w-sm w-full mx-auto mb-3 flex space-x-4">
                <div className="grow shrink-0">
                  <span className="font-semibold">{movement.nameAreaFrom}</span>
                  <div className="flex text-neutral-500">
                    <ArrowSmDownIcon className="w-6" />{movement.nameAccessPoint}
                  </div>
                  <span className="font-semibold">{movement.nameAreaTo}</span>
                </div>
                <div className="shrink-0 grow-0 flex flex-col items-end">
                  <div className="px-2 py-1 text-sm bg-neutral-700 rounded whitespace-nowrap">
                    {movement.nameCredential}
                  </div>
                  <div className="mt-auto text-right text-xs text-neutral-500">
                    {this.timeSince(new Date(movement.time))}
                  </div>
                </div>
              </Link>)
          }) : '')
        )}
      </div>
    );
  }

  timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return "years".t(Math.floor(interval));
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return "months".t(Math.floor(interval));
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return "days".t(Math.floor(interval));
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return "hours".t(Math.floor(interval));
    }
    interval = seconds / 60;
    if (interval > 1) {
      return "minutes".t(Math.floor(interval));
    }
    return "seconds".t(Math.floor(seconds));
  }

}