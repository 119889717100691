import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginIcon } from '@heroicons/react/outline'
import axios from "axios";
import { encode } from 'js-base64';
import Config from "../config";

export default class Login extends Component {
    render() {
        if (sessionStorage.getItem('auth')) {
            window.location = '/';
        }

        return (
            <div>
                <ToastContainer
                    theme="dark"
                />
                <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-md w-full space-y-8">
                        <div>
                            <img
                                className="mx-auto h-40 w-auto"
                                src="/logo.png"
                                alt="Workflow"
                            />
                            <h2 className="mt-6 text-center text-3xl font-extrabold">{"loginA".t()}</h2>
                        </div>
                        <div className="mt-8 space-y-6">
                            <input type="hidden" name="remember" defaultValue="true" />
                            <div className="rounded-md shadow-sm -space-y-px">
                                <div>
                                    <label htmlFor="email-address" className="sr-only">
                                        {"email".t()}
                                    </label>
                                    <input
                                        id="username"
                                        name="username"
                                        type="text"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                                        placeholder={"username".t()}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="password" className="sr-only">
                                        {"password".t()}
                                    </label>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                                        placeholder={"password".t()}
                                    />
                                </div>
                            </div>

                            <div className="flex items-center justify-between hidden">
                                <div className="flex items-center">
                                    <input
                                        id="remember-me"
                                        name="remember-me"
                                        type="checkbox"
                                        className="h-4 w-4 focus:ring-red-500 border-gray-300 rounded"
                                    />
                                    <label htmlFor="remember-me" className="ml-2 block text-sm">
                                        {"rememberMe".t()}
                                    </label>
                                </div>

                                <div className="text-sm">
                                    <a href="#" className="font-medium text-red-600 hover:text-red-500">
                                        {"forgotPassword".t()}
                                    </a>
                                </div>
                            </div>

                            <div>
                                <button
                                    id="submit"
                                    type="button"
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                    onClick={this.submit.bind(this)}
                                >
                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                        <LoginIcon className="h-5 w-5 text-red-500 group-hover:text-red-400" aria-hidden="true" />
                                    </span>
                                    {"login".t()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    submit() {
        const username = document.getElementById('username').value;
        const password = document.getElementById('password').value;

        let auth = encode(username + ":" + password);

        document.getElementById('submit').disabled = true;

        const instance = axios.create({
            timeout: 60000,
            headers: {
                'Authorization': 'Basic ' + auth,
                'Referrer-Policy': 'origin-when-cross-origin',
                'Access-Control-Allow-Origin': '*',
                'Allow': 'GET, POST, OPTIONS',
            }
        }).get(Config.apiUrl + 'user/username/' + username)
            .then(function (response) {
                sessionStorage.setItem('auth', auth);
                sessionStorage.setItem('user', encode(JSON.stringify(response.data)));
                window.location = '/';
            })
            .catch(function (error) {
                document.getElementById('submit').disabled = false;
                toast.error("loginError".t());
            })
    }
}