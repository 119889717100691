import React, {useEffect, useState} from 'react';
import Navbar from './components/Navbar/Navbar';
import {Outlet} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import CameraContext, {CameraProvider} from "./CameraContext";
import {socket} from "./socket";
import {InOutProvider} from "./InOutContext";

function App() {
    useEffect(() => {
        if (!socket.connected) {
            socket.connect();
        }

        return () => {
            if (socket.connected) {
                socket.disconnect();
            }
        };
    }, []);

    return (
        <div className="App bg-neutral-900 h-full">
            <CameraProvider>
                <InOutProvider>
                    <Navbar></Navbar>
                    <ToastContainer theme="dark"/>
                    <main className='flex flex-col z-0 p-4 mt-[57px]'>
                        <Outlet/>
                    </main>
                </InOutProvider>
            </CameraProvider>
        </div>
    );
}

export default App;
