import React, {Component, useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import moment from "moment";
import {ArrowSmRightIcon, PlusIcon, QrcodeIcon} from '@heroicons/react/outline';
import {FlagIcon, BanIcon, SearchIcon, CheckCircleIcon, ExclamationCircleIcon} from '@heroicons/react/solid';
import {QrReader} from 'react-qr-reader';
import Config from "../../config";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CameraContext from "../../CameraContext";
import InOutContext from "../../InOutContext";

const CreateWrapper = () => {
    const inOut = useInOut();
    return <Create inOut={inOut} />;
};
export default CreateWrapper;

export const useInOut = () => {
    const { inOut } = useContext(InOutContext);
    return inOut;
};

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allowRead: true,
            ucid: null,
            data: null,
            areas: null,
            accessPoint: null,
            isLoading: false,
            oldInOut: null,
            inOut: props.inOut,
            error: null,
            errorReason: "",
        };
    }

    render() {
        return (<>
            <div>
                {this.state.error === true && (<div className="absolute bg-red-700 -top-20 left-0 z-10 h-screen w-screen flex flex-col justify-center items-center">
                    <ExclamationCircleIcon className="size-44 text-red-200 font-semibold"/>
                    <div className="text-lg text-red-200 font-semibold">Movimento não registado</div>
                    <div className="text-red-400 font-semibold text-center px-4">{this.state.errorReason}</div>
                </div>)}
                {this.state.error === false && (<div className="absolute bg-green-700 -top-20 left-0 z-10 h-screen w-screen flex flex-col justify-center items-center">
                    <CheckCircleIcon className="size-44 text-green-200 font-semibold"/>
                    <div className="text-lg text-green-200 font-semibold">Movimento registado com sucesso</div>
                </div>)}
            </div>
            <Camera getCredential={this.getCredential.bind(this)} />
            <div className="bg-neutral-900 rounded-t-2xl z-10 absolute top-3/4 w-screen left-0 border-x border-t border-neutral-800">
                <div className="w-1/2 h-1 rounded bg-neutral-800 mt-2 mx-auto"></div>
                {(this.state.isLoading ? <div className="px-4 pb-4 pt-2 flex w-full space-x-4 animate-pulse mb-24">
                    <div className="w-[5rem] h-[5rem] bg-neutral-600 rounded-md basis-[5rem] shrink-0"></div>
                    <div className="mt-auto grow-1 space-y-0.5">
                        <div>
                            <span className="text font-semibold mr-2 px-2.5 py-0.5 rounded-md bg-neutral-600 text-transparent">--------------</span>
                        </div>
                        <div>
                            <span className="text-xs font-semibold mr-2 px-2.5 py-0.5 rounded bg-neutral-700 text-transparent">------------------------</span>
                        </div>
                        <div>
                            <span className="text-xs font-semibold mr-2 px-2.5 rounded bg-neutral-700 text-transparent">--------</span>
                        </div>
                    </div>
                </div> : <>
                    {(!!this.state.data && (this.state.error !== null || Config.user?.role === "admin") ? <>
                        <div className="px-4 pb-4 pt-2 flex w-full space-x-4">
                            <div
                                className="w-[5rem] h-[5rem] bg-neutral-600 rounded-md basis-[5rem] shrink-0"></div>
                            <div
                                className="mt-auto grow-1 whitespace-nowrap text-ellipsis overflow-hidden">
                                <div
                                    className=" text-xl font-semibold whitespace-nowrap text-ellipsis overflow-hidden -mb-1">{this.state.data !== null ? (this.state.data[0].carrier != null ? this.state.data[0].carrier.name : this.state.data[0].ucid) : ""}</div>
                                <div
                                    className="whitespace-nowrap text-ellipsis overflow-hidden text-white/70">{this.state.data !== null ? this.state.data[0].entity.name : ""}</div>
                                <div>
                                                    <span
                                                        className="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.data !== null ? this.state.data[0].entity.entityType.name : ""}</span>
                                    {this.state.data !== null && this.state.data[0].flagged > 0 ? <span
                                        className="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-1.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900">{this.state.data[0].flagged}<FlagIcon
                                        className="w-3 inline mb-1 ml-0.5"/></span> : ""}

                                </div>
                            </div>
                        </div>
                        {this.state.data != null && !!this.state.data[0].blocked ? <div
                            className="p-3 bg-red-700 border-t uppercase text-center font-semibold border-neutral-800">
                            <BanIcon className="w-4 inline mb-1 mr-1"/>Credencial bloqueada
                        </div> : ""}
                        {this.state.data != null && (!this.state.data[0].blocked || Config.user?.role === "admin") ? <div className="p-4 border-t border-neutral-800">
                            <div className="flex text-lg pb-4">
                                <div
                                    className="font-semibold text-2xl grow whitespace-nowrap text-ellipsis overflow-hidden w-5/12">{this.state.areas.find(a => a.id === this.state.accessPoint.areas[this.state.inOut === 'in' ? 0 : 1])?.name}</div>
                                <ArrowSmRightIcon
                                    className="w-2/12 h-6 my-auto text-neutral-500 shrink-0"/>
                                <div
                                    className="font-semibold grow whitespace-nowrap text-ellipsis overflow-hidden w-5/12 text-right">
                                    {Config.user?.role === "admin" ? <select name="areaToSelect"
                                                                             className="bg-neutral-800 border-neutral-700 rounded">{(this.state.areas !== null ? this.state.areas.map((area) => {
                                        return this.state.data[0].idCurrentArea !== null && this.state.data[0].idCurrentArea !== area.id ? <option key={area.id}
                                                                                                                                                   value={area.id}>{area.name}</option> : "";
                                    }) : "")}</select> : (this.state.data !== null && this.state.areas !== null && this.state.accessPoint !== null ? this.state.areas.find(a => a.id === this.state.accessPoint.areas[this.state.inOut === 'in' ? 1 : 0])?.name : "")}
                                </div>
                            </div>
                            {Config.user?.role === "admin" ? <button onClick={() => this.registerMovement()} type="button"
                                                                     className="text-white bg-gradient-to-r w-full from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">Movimentar
                            </button> : ""}
                            <div className="flex space-x-2">
                                <button onClick={() => this.flagCredential()} type="button"
                                        className="text-white bg-gradient-to-r grow from-yellow-400 via-yellow-500 to-yellow-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-yellow-800 shadow-lg shadow-yellow-500/50 dark:shadow-lg dark:shadow-yellow-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Marcar
                                    como suspeita
                                </button>
                                {Config.user?.role === "admin" ? <button onClick={() => this.blockCredential()} type="button"
                                                                         className="text-white bg-gradient-to-r grow from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Bloquear
                                </button> : ""}
                            </div>
                        </div> : ""}
                    </> : <div className="text-center p-4 h-54">
                        <QrcodeIcon className="h-20 w-20 text-neutral-500 mx-auto mt-auto"/>
                        <div className="text-center font-semibold text-neutral-300 mb-auto">Faça scan do
                            codigo QR de uma credencial, ou insira o código manualmente abaixo.
                        </div>
                    </div>)}
                    <div className="flex p-4 border-t border-neutral-800">
                        <input name="searchCredentialInput" type="text"
                               id="searchCredentialInput"
                               disabled={this.state.error === true}
                               className="bg-neutral-800 rounded-l border-neutral-700 flex-grow"
                               placeholder="Procurar credencial..."/>
                        <button disabled={this.state.error === true} onClick={() => this.searchCredential()}
                                className="px-3 text-sm font-medium bg-neutral-700 rounded-r focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                            <SearchIcon className="w-6 h-6" aria-hidden="true"></SearchIcon>
                        </button>
                    </div>
                </>)}
            </div>
        </>);
    }

    async componentDidMount() {
        this.create();
    }

    create() {
        let auth = sessionStorage.getItem('auth');
        let user = Config.user;
        let request = axios.create({
            timeout: 60000, headers: {
                'Authorization': 'Basic ' + auth, 'Referrer-Policy': 'origin-when-cross-origin',
            }
        });
        let request2 = axios.create({
            timeout: 60000, headers: {
                'Authorization': 'Basic ' + auth, 'Referrer-Policy': 'origin-when-cross-origin',
            }
        });

        request.get(Config.apiUrl + 'accesspoint/' + user.idAccessPoint)
            .then((response) => {
                this.setState({
                    accessPoint: response?.data,
                });

            }).catch(function (error) {
            console.log(error);
        });

        request2.get(Config.apiUrl + 'area/event/' + user.currentEvent)
            .then((response) => {
                this.setState({
                    areas: response?.data,
                });
            }).catch(function (error) {
            console.log(error);
        });

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.inOut !== this.props.inOut) {
            this.setState({ inOut: this.props.inOut });
        }

        if (prevState.error !== this.state.error && this.state.error !== null) {
            if (this.state.error) {
                setTimeout(() => {
                    this.reset();
                }, 8000);
            } else {
                setTimeout(() => {
                    this.reset();
                }, 2000);
            }
        }
    }

    searchCredential() {
        let result = document.getElementsByName("searchCredentialInput")[0].value

        this.reset();

        if (this.state.allowRead && result !== this.state.ucid) {
            this.setState({
                allowRead: false, isLoading: true, ucid: result,
            });

            axios.create({
                headers: {
                    'Authorization': 'Basic ' + sessionStorage.getItem('auth'), 'Referrer-Policy': 'origin-when-cross-origin',
                }
            }).get(Config.apiUrl + 'credential/byucid/' + result)
                .then((response) => {
                    if (response.statusText === 'OK' && response.data.length > 0) {
                        this.setState({
                            allowRead: true, isLoading: false, data: response?.data,
                        });

                        if (Config.user?.role !== "admin") this.registerMovement();
                    }
                })
                .catch((error) => {
                    this.setState({
                        allowRead: true,
                        isLoading: false,
                        error: true,
                        errorReason: "404credential".t(),
                    });
                })
        }
    }

    getCredential(result) {
        if (this.state.error !== null) return;

        this.reset();

        if (!!result && this.state.allowRead && result?.text !== this.state.ucid) {
            this.setState({
                allowRead: false, isLoading: true, ucid: result?.text,
            });

            axios.create({
                headers: {
                    'Authorization': 'Basic ' + sessionStorage.getItem('auth'), 'Referrer-Policy': 'origin-when-cross-origin', 'Access-Control-Allow-Origin': '*', 'Allow': 'GET, POST, OPTIONS',
                }
            }).get(Config.apiUrl + 'credential/byucid/' + this.state.ucid)
                .then((response) => {
                    if (response.statusText === 'OK' && response.data.length > 0) {
                        this.setState({
                            allowRead: true, isLoading: false, data: response?.data,
                        });

                        if (Config.user?.role !== "admin") this.registerMovement();
                    }
                })
                .catch((error) => {
                    this.setState({
                        allowRead: true,
                        isLoading: false,
                        error: true,
                        errorReason: "404credential".t(),
                    });
                })
        }
    }

    registerMovement() {
        let credencial = this.state.data[0];
        const areaFrom = this.state.areas.find(a => a.id === this.state.accessPoint.areas[this.state.inOut === 'in' ? 0 : 1])?.id;
        const areaTo = this.state.areas.find(a => a.id === this.state.accessPoint.areas[this.state.inOut === 'in' ? 1 : 0])?.id;

        let isMovementAllowed = this.isMovementAllowed(areaTo);
        if (!isMovementAllowed.allowed && Config.user.role !== 'admin') {
            this.setState({
                error: true, errorReason: isMovementAllowed.reason, ucid: null
            });
            return;
        }

        axios.create({
            headers: {
                'Authorization': 'Basic ' + sessionStorage.getItem('auth'), 'Referrer-Policy': 'origin-when-cross-origin', 'Access-Control-Allow-Origin': '*', 'Allow': 'GET, POST, OPTIONS',
            }
        }).post(Config.apiUrl + 'movement', {
            "idCredential": credencial.id, "idAreaTo": areaTo, "idAreaFrom": areaFrom, "idAccessPoint": this.state.accessPoint.id, "idUser": Config.user.id
        })
            .then((response) => {
                this.setState({
                    error: false, ucid: null
                });
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    this.setState({
                        error: true, errorReason: error.response.data.message,
                    });
                } else {
                    this.setState({
                        error: true, errorReason: "errorMovement".t(),
                    });
                }
            })
    }

    flagCredential() {
        let credencial = this.state.data[0];
        axios.create({
            headers: {
                'Authorization': 'Basic ' + sessionStorage.getItem('auth'), 'Referrer-Policy': 'origin-when-cross-origin', 'Access-Control-Allow-Origin': '*', 'Allow': 'GET, POST, OPTIONS',
            }
        }).put(Config.apiUrl + 'credential/flag/' + credencial.id).then((response) => {
            console.log(response)
            if (response.statusText === 'OK' && response.data.length > 0) {
                this.setState({
                    data: response?.data,
                });
            }
            toast.success("Credencial marcada com sucesso.");
        }).catch((error) => {
            toast.error("Erro ao marcar credencial.");
        })
    }

    blockCredential() {
        let credencial = this.state.data[0];
        axios.create({
            headers: {
                'Authorization': 'Basic ' + sessionStorage.getItem('auth'), 'Referrer-Policy': 'origin-when-cross-origin', 'Access-Control-Allow-Origin': '*', 'Allow': 'GET, POST, OPTIONS',
            }
        }).put(Config.apiUrl + 'credential/block/' + credencial.id).then((response) => {
            console.log(response)
            if (response.statusText === 'OK' && response.data.length > 0) {
                this.setState({
                    data: response?.data,
                });
            }
            toast.success("Credencial bloqueada com sucesso.");
        }).catch((error) => {
            toast.error("Erro ao bloquear credencial.");
        })
    }

    isMovementAllowed(destination) {
        let credencial = this.state.data[0], now = moment();

        // return false and the reason why is false if
        // - credential currentArea is not in the accessPoint's areas
        // - credential is in the same area as destination
        // - now is between credential's alowedStart and allowedEnd, if alowedStart and allowedEnd are not null
        // - destination is not in the credential's accessibleAreas

        if (!this.state.accessPoint.areas.find(a => a == credencial.idCurrentArea)) {
            return {
                allowed: false, reason: "errorAreaNotInAccessPoint".t()
            }
        } else if (credencial.idCurrentArea == destination) {
            return {
                allowed: false, reason: "errorSameArea".t()
            }
        } else if (!credencial.accessibleAreas.find(a => a == destination)) {
            return {
                allowed: false, reason: "errorNoAccess".t()
            }
        } else if (!!credencial.allowedStart && !!credencial.allowedEnd) {
            if (now.isBetween(credencial.allowedStart, credencial.allowedEnd)) {
                return {
                    allowed: true, reason: ""
                }
            } else {
                return {
                    allowed: false, reason: "errorNotInTimeRange".t()
                }
            }
        } else {
            return {
                allowed: true, reason: ""
            }
        }

    }

    reset() {
        this.setState({
            error: null,
            errorReason: "",
            ucid: null,
        });
    }
}

function Camera({ getCredential }) {
    const { camera } = useContext(CameraContext);

    const qrReader = <QrReader
            scanDelay={1000}
            constraints={{facingMode: "environment"}}
            videoContainerStyle={{all: "unset"}}
            videoStyle={{height: "unset", transform: "scaleX(0)", "object-fit": "cover"}}
            onResult={(res) => {
                getCredential(res)
            }}
        />;

    useEffect(() => {
        const input = document.getElementById("searchCredentialInput");
        if (!camera && input) {
            input.focus();
            input.value = "";
            const handleInput = (event) => {
                if (event.target.value.length === 8) {
                    input.blur();
                    getCredential({ text: input.value });
                }
            };
            input.addEventListener("input", handleInput);
            return () => {
                input.removeEventListener("input", handleInput);
            };
        }
    }, [camera, getCredential]);

    return camera && qrReader;
}