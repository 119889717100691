import {createContext, useState} from "react";

const InOutContext = createContext("in");

export const InOutProvider = ({ children }) => {
    const [inOut, setInOut] = useState("in");

    // get value from select
    const selectInOut = (event) => {
        setInOut(event.target.value);
    };

    return (
        <InOutContext.Provider value={{ inOut, selectInOut }}>
            {children}
        </InOutContext.Provider>
    );
};

export default InOutContext;