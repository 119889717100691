import React, {Component, Fragment, useContext, useEffect, useState} from "react";
import {UserCircleIcon, LogoutIcon, AdjustmentsIcon, PlusIcon, ArrowLeftIcon, SearchCircleIcon, LightBulbIcon, CameraIcon} from '@heroicons/react/outline'
import {Menu, Transition} from '@headlessui/react'
import Sidebar from '../Sidebar/Sidebar';
import {Link, useLocation} from "react-router-dom";
import {socket} from "../../socket";
import CameraContext from "../../CameraContext";
import InOutContext from "../../InOutContext";

export default class Navbar extends Component {
    render() {
        return (
            <div>
                <header className='flex border-b space-x-3 border-neutral-800 fixed w-full top-0 z-30 p-4 bg-neutral-900/50 backdrop-blur-sm'>
                    <div className='flex grow'>
                        <Sidebar></Sidebar>
                        <NavabarTitle className="whitespace-nowrap text-ellipsis overflow-hidden"/>
                    </div>
                    <NavabarActions/>
                    <Menu as="div" className="relative text-left flex">
                        <Menu.Button
                            className="inline-flex justify-center w-full text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                            <UserCircleIcon
                                className="w-6 h-6"
                                aria-hidden="true"
                            />
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 w-40 mt-12 origin-top-right bg-neutral-700 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-neutral-700 ring-opacity-5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    {/* <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${active ? 'bg-red-500 text-white' : 'text-white'
                          } group flex rounded-md items-center justify-between w-full px-2 py-2 text-sm font-semibold`}
                      >
                        <div>{'settings'.t()}</div>
                        <div><AdjustmentsIcon className='h-4 w-4 flex-grow-1' /></div>
                      </button>
                    )}
                  </Menu.Item> */}
                                    <Menu.Item>
                                        {({active}) => (
                                            <button
                                                className={`${active ? 'bg-red-500 text-white' : 'text-white'
                                                } group flex rounded-md items-center justify-between w-full px-2 py-2 text-sm font-semibold`}
                                                onClick={() => {
                                                    sessionStorage.clear();
                                                    window.location = '/';

                                                    if (socket.connected) {
                                                        socket.disconnect();
                                                    }
                                                }}
                                            >
                                                <div>{'logout'.t()}</div>
                                                <div><LogoutIcon className='h-4 w-4 flex-grow-1'/></div>
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </header>
            </div>
        );
    }
}

function NavabarTitle() {
    const location = useLocation();
    const { inOut } = useContext(InOutContext);

    if (location.pathname === '/movements/create') {
        if (inOut === 'in') {
            return <span className="ml-3 whitespace-nowrap text-ellipsis overflow-hidden">{'entry'.t()}</span>;
        } else if (inOut === 'out') {
            return <span className="ml-3 whitespace-nowrap text-ellipsis overflow-hidden">{'exit'.t()}</span>;
        }
    }

    return <span className="ml-3 whitespace-nowrap text-ellipsis overflow-hidden">{location.pathname.t()}</span>
}

function NavabarActions() {
    const location = useLocation();
    const { camera, toggleCamera } = useContext(CameraContext);

    switch (location.pathname) {
        case '/movements':
            return <Link to={'/movements/create'}
                         className="inline-flex justify-center text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <PlusIcon className="w-6 h-6" aria-hidden="true"/>
            </Link>
        case '/movements/create':
            return <>
            {/*<Link to={'/movements'}*/}
            {/*               className="inline-flex justify-center text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">*/}
            {/*    <ArrowLeftIcon className="w-6 h-6" aria-hidden="true"/>*/}
            {/*</Link>*/}
                {/* <Link to={'/movements'} className="inline-flex justify-center text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        <LightBulbIcon className="w-6 h-6" aria-hidden="true" />
      </Link> */}
                <Menu as="div" className="relative text-left flex">
                    <Menu.Button
                        className="inline-flex justify-center w-full text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        <CameraIcon
                            className="w-6 h-6"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 mt-12 origin-top-right bg-neutral-700 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-neutral-700 ring-opacity-5 focus:outline-none">
                            <div className="p-2">
                                <label className='flex cursor-pointer items-center'>
                                    <div className='relative'>
                                        <input
                                            type='checkbox'
                                            checked={camera}
                                            onChange={toggleCamera}
                                            className='sr-only'
                                        />
                                        <div className={`block h-8 w-14 rounded-full transition ${camera ? 'bg-green-500' : 'bg-gray-500'}`}></div>
                                        <div className={`dot absolute left-1 top-1 h-6 w-6 rounded-full bg-white transition transform ${camera ? 'translate-x-full' : ''}`}/>
                                    </div>
                                </label>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </>
        case '/credentials':
            return <Link to={'/credentials/create'}
                         className="inline-flex justify-center text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <PlusIcon className="w-6 h-6" aria-hidden="true"/>
            </Link>
        case '/credentials/create':
            return <Link to={'/credentials'}
                         className="inline-flex justify-center text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <ArrowLeftIcon className="w-6 h-6" aria-hidden="true"/>
            </Link>

    }

    return <></>

}