import React, { Component } from "react";

export default class Error extends Component {

  render() {
    return (
      <div className="text-center">
        <div className=" text-9xl font-bold">404</div>
        <div className=" text-neutral-400">{"error404".t()}</div> 
      </div>
    );
  }
}