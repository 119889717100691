import React, {Component, useContext} from "react";
import axios from "axios";
import Config from "../config";
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { encode, decode } from 'js-base64';
import BatteryDisplay from "../components/BatteryDisplay";
import CameraContext from "../CameraContext";
import {Input} from "postcss";
import InOutContext from "../InOutContext";

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accessPoints: [],
            accessPoint: Config.user.idAccessPoint,
        };
    }

    render() {
        return (
            <>
                <div className="border border-neutral-800 rounded-md p-4 mb-8">
                    <label htmlFor="accessPoint" className="text-semibold">Ponto de acesso</label>
                    <div className="flex w-full mt-2">
                        <select onChange={this.updateAccessPoint} name="accessPoint" id="accessPoint" value={this.state.accessPoint} className="bg-neutral-800 border-neutral-700 rounded flex-grow">
                            {this.state.accessPoints.map((accessPoint) => {
                                    return <option key={accessPoint.id} value={accessPoint.id}>{accessPoint.name}</option>
                                }
                            )}
                        </select>
                    </div>
                    <InOutComponent />
                    {/*<div className="flex w-full mt-2">*/}
                    {/*    <select onChange={this.updateInOut} value={this.state.inOut} name="accessType" id="accessType" className="bg-neutral-800 border-neutral-700 rounded flex-grow">*/}
                    {/*        <option value="in">Entrada</option>*/}
                    {/*        <option value="out">Saída</option>*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                </div>
                {this.state.accessPoints.length > 0 ? (
                    <BatteryDisplay accessPoint={this.state.accessPoints.find(ap => ap.id === this.state.accessPoint)?.name} />
                ) : null}
            </>
        );
    }

    async componentDidMount() {
        this.create();
    }

    create() {
        let auth = sessionStorage.getItem('auth');
        let user = Config.user;
        let request = axios.create({
            timeout: 60000,
            headers: {
                'Authorization': 'Basic ' + auth,
                'Referrer-Policy': 'origin-when-cross-origin',
                'Access-Control-Allow-Origin': Config.apiUrl,
            }
        })

        request.get(Config.apiUrl + 'accesspoint/event/' + user.currentEvent)
            .then((response) => {
                if (response.statusText === 'OK' && response.data.length > 0) {
                    this.setState({
                        accessPoints: response.data,
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    updateInOut = (event) => {
        this.setState({
            inOut: event.target.value,
        });

        sessionStorage.removeItem('inOut');
        sessionStorage.setItem('inOut', event.target.value);
    }

    updateAccessPoint = (event) => {
        let auth = sessionStorage.getItem('auth');
        let user = Config.user;
        let request = axios.create({
            timeout: 60000,
            headers: {
                'Authorization': 'Basic ' + auth,
                'Referrer-Policy': 'origin-when-cross-origin',
                'Access-Control-Allow-Origin': Config.apiUrl,
            }
        })

        request.put(Config.apiUrl + 'user/accesspoint/' + user.id, {
            accessPointId: event.target.value,
        })
            .then((response) => {
                console.log(response);
                if (response.statusText === 'OK') {
                    this.setState({
                        accessPoint: response.data.idAccessPoint,
                        inOut: "in",
                    });

                    sessionStorage.removeItem('inOut');
                    sessionStorage.setItem('inOut', this.state.inOut);

                    sessionStorage.removeItem('user');
                    sessionStorage.setItem('user', encode(JSON.stringify(response.data)));
                    Config.user = !!sessionStorage.getItem('user') ? JSON.parse(decode(sessionStorage.getItem('user'))) : null;
                }
            }).catch(function (error) {
                console.log(error);
            });

    }
}

function InOutComponent() {
    const { inOut, selectInOut } = useContext(InOutContext);

    return (
        <div className="flex w-full mt-2">
            <select onChange={selectInOut} name="accessPoint" id="accessPoint" value={inOut} className="bg-neutral-800 border-neutral-700 rounded flex-grow">
                <option value="in">Entrada</option>
                <option value="out">Saída</option>
            </select>
        </div>
    );
}