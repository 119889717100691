import React, { Component } from "react";
import Config from "../config";
import {socket} from "../socket";

export default class BatteryDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            level: null,
            charging: null,
            users: {},
        };
    }

    render() {
        return (
            <div className="flex justify-center">
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                    {Config.user.role === 'admin' ? (
                        Object.keys(this.state.users).map((user) => {
                            const userBatteryLevel = this.state.users[user].battery;
                            const userBatteryLevelClass = userBatteryLevel > 50 ? 'bg-green-500' : userBatteryLevel > 25 ? 'bg-yellow-500' : 'bg-red-500';
                            const userBatteryLevelWidth = `${userBatteryLevel}%`;

                            return (
                                <div key={user} className="border border-neutral-800 rounded-md p-4 flex flex-col justify-center items-center">
                                    <div className="flex flex-row items-center">
                                        <div className="border-2 max-h-8 rounded h-7 w-20 p-1">
                                            <div className={`h-full rounded-sm ${userBatteryLevelClass}`} style={{width: userBatteryLevelWidth}}></div>
                                        </div>
                                        <div className="bg-zinc-200 h-4 w-1 rounded-tr-md rounded-br-md"/>
                                    </div>
                                    <div className="rounded max-w-20 overflow-hidden whitespace-nowrap truncate">{this.state.users[user].user}</div>
                                    <div className="rounded text-sm text-gray-400 overflow-hidden whitespace-nowrap truncate">{this.state.users[user].accessPoint}</div>
                                </div>
                            );
                        })
                    ) : null}
                </div>
            </div>
        );
    }

    async componentDidMount() {
        this.create();

        socket.emit('batteries');
        socket.on('batteries', (data) => {
            this.setState({
                users: data
            });
        });
    }

    componentWillUnmount() {
        clearInterval(this.batteryInterval);
        socket.off('disconnect', this.handleDisconnect);
        socket.off('battery');
        if (Config.user.role === 'porteiro') socket.off('userDisconnected');
    }

    create() {
        this.updateBatteryStatus();

        socket.on('userDisconnected', (socketId) => {
            const users = this.state.users;
            delete users[socketId];

            this.setState({
                users: users
            });
        });

        this.batteryInterval = setInterval(this.updateBatteryStatus, 5000);
    }

    updateBatteryStatus = async () => {
        try {
            if (Config.user.role === 'porteiro') {
                const battery = await navigator.getBattery();
                this.setState({
                    level: battery.level * 100,
                    charging: battery.charging,
                });

                if (socket.connected) {
                    socket.emit('battery', {
                        user: Config.user.displayName,
                        id: socket.id,
                        battery: this.state.level,
                        accessPoint: this.props.accessPoint,
                    });
                } else {
                    console.error('Socket is not connected');
                }
            }

            socket.on('battery', (data) => {
                const users = this.state.users;
                users[data.id] = {
                    user: data.user,
                    battery: data.battery,
                    accessPoint: data.accessPoint,
                };

                this.setState({
                    users: users
                });
            });
        } catch (error) {
            console.error("Failed to get battery status:", error);
        }
    };
}