import React, {Component, useContext} from "react";
import {MenuIcon, XIcon, HomeIcon, SwitchHorizontalIcon, IdentificationIcon, ArrowCircleLeftIcon, ArrowCircleRightIcon} from '@heroicons/react/outline'
import {OffCanvas, OffCanvasMenu, OffCanvasBody} from "react-offcanvas";
import {Link, useMatch, useResolvedPath} from "react-router-dom";

import './Sidebar.css'
import {ArrowCircleLeftIcon as ArrowCircleLeftIconSolid, ArrowCircleRightIcon as ArrowCircleRightIconSolid} from "@heroicons/react/solid";
import InOutContext from "../../InOutContext";

export default class Sidebar extends Component {
    state = {
        isMenuOpened: false,
        inOut: "in",
    }

    render() {
        return (
            <OffCanvas
                width={300}
                transitionDuration={300}
                effect={"overlay"}
                isMenuOpened={this.state.isMenuOpened}
                position={"left"}
            >
                <OffCanvasBody>
                    <div className="flex">
                        <button onClick={this.handleClick.bind(this)} className="justify-center text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus-ring">
                            <MenuIcon className='h-6 w-6'/>
                        </button>
                    </div>
                </OffCanvasBody>
                <OffCanvasMenu className={"bg-neutral-900 h-screen z-50 border-r border-neutral-800 flex flex-col"}>
                    <div className="p-4 flex justify-between">
                        <div className="">
                            <div className="font-semibold text-lg -mb-1">{"eventusgest".t()}</div>
                            <div className="text-neutral-300 text-sm">Powering Fesmonte 2022</div>
                        </div>
                        <button onClick={this.handleClick.bind(this)} className="justify-center text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus-ring">
                            <XIcon className='h-6 w-6'/>
                        </button>
                    </div>
                    <ul className="px-2">
                        <li><SidebarLink onClick={this.handleClick.bind(this)} to={'/'}><HomeIcon className="sidebar-icon"/>Dashboard</SidebarLink></li>
                        <li><SidebarLink onClick={this.handleClick.bind(this)} to={'/movements/create'}><SwitchHorizontalIcon className="sidebar-icon"/>Movimentos</SidebarLink></li>
                        {/* <li><SidebarLink onClick={this.handleClick.bind(this)} to={'/credentials'}><IdentificationIcon className="sidebar-icon" />Credenciais</SidebarLink></li> */}
                    </ul>
                    <InOutButtons/>
                    {/*  <button onClick={() => this.setInOut("in")} className={`flex items-center justify-center w-full p-2 font-medium transition duration-200 ${entradasClass}`}>*/}
                    {/*    <ArrowCircleRightIcon className='h-6 w-6'/>*/}
                    {/*    <span className="ml-2">Entradas</span>*/}
                    {/*  </button>*/}
                    {/*  <div className="border-l border-neutral-800"></div>*/}
                    {/*  <button onClick={() => this.setInOut("out")} className={`flex items-center justify-center w-full p-2 font-medium transition duration-200 ${saidasClass}`}>*/}
                    {/*    <ArrowCircleLeftIcon className='h-6 w-6'/>*/}
                    {/*    <span className="ml-2">Saídas</span>*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                </OffCanvasMenu>
            </OffCanvas>
        );
    }

    handleClick() {
        // toggles the menu opened state
        this.setState({isMenuOpened: !this.state.isMenuOpened});
    }
}

function InOutButtons() {
    const { inOut, selectInOut } = useContext(InOutContext);
    const entradasClass = inOut === 'in' ? 'bg-white text-black' : 'bg-opacity-20 hover:bg-opacity-30';
    const saidasClass = inOut === 'out' ? 'bg-white text-black' : 'bg-opacity-20 hover:bg-opacity-30';

    return (
        <div className="flex border-t border-neutral-800 z-50">
            <button onClick={() => selectInOut({ target: { value: "in" } })} className={`flex items-center justify-center w-full p-2 font-medium transition duration-200 z-50 ${entradasClass}`}>
                {inOut === 'in' ? <ArrowCircleRightIconSolid className='h-6 w-6' /> : <ArrowCircleRightIcon className='h-6 w-6' />}
                <span className="ml-2">Entrada</span>
            </button>
            <div className="border-l border-neutral-800"></div>
            <button onClick={() => selectInOut({ target: { value: "out" } })} className={`flex items-center justify-center w-full p-2 font-medium transition duration-200 z-50 ${saidasClass}`}>
                {inOut === 'out' ? <ArrowCircleLeftIconSolid className='h-6 w-6' /> : <ArrowCircleLeftIcon className='h-6 w-6' />}
                <span className="ml-2">Saída</span>
            </button>
        </div>
    );
}

function SidebarLink({children, to, ...props}) {
    let resolved = useResolvedPath(to);
    let match = useMatch({path: resolved.pathname});

    return (
        <div>
            <Link
                to={to}
                className={
                    "sidebar-item" + (!!match ? " active" : "")
                }
                {...props}
            >
                {children}
            </Link>
        </div>
    );
}