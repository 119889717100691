import {createContext, useState} from "react";

const CameraContext = createContext(true);

export const CameraProvider = ({ children }) => {
    const [camera, setCamera] = useState(true);

    const toggleCamera = () => {
        setCamera(prevCamera => !prevCamera)
    };

    return (
        <CameraContext.Provider value={{ camera, toggleCamera }}>
            {children}
        </CameraContext.Provider>
    );
};

export default CameraContext;